<template>
	<div class="account-details">
		<div class="wrapper" style="width: 12rem;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>销售信息</el-breadcrumb-item>
			</el-breadcrumb>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="main-form">
				<div class="required">
					<el-form-item label="车辆VIN码:" prop="vin">
						<el-col :span="15">
							<el-input v-model="vinUpper" placeholder="自动识别大小写"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								填写规范的车辆VIN码后8位<br />
								示例：<span class="red">N1F11332</span>
							</div>
						</el-col>
					</el-form-item>
                    <el-form-item label="查询码:" prop="code">
                        <el-col :span="15">
                            <el-input v-model="ruleForm.code" placeholder="请输入查询码"></el-input>
                        </el-col>
                        <el-col :span="9">
                            <el-button @click="generateCode">生成查询码</el-button>
                        </el-col>
                    </el-form-item>
					<el-form-item label="零件名称:" prop="productName">
						<el-col :span="15">
							<el-input v-model="ruleForm.productName"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								填写销售零件名称<br />
								示例：<span class="red">驾驶室总成</span>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="零件号码:" prop="productCode">
						<el-col :span="15">
							<el-input v-model="ruleForm.productCode" placeholder="自动识别大小写"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								填写完整的零件备品号<br />
								示例：<span class="red">GP5000010-HB83H-DY887F-010-#5-P9</span>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="销售车型码:" prop="other1">
						<el-col :span="15">
							<el-input v-model="ruleForm.other1" placeholder="请输入销售车型码"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								填写销售车型码<br />
								示例：<span class="red">CA4163P1K2E4A80</span>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="品牌信息:" prop="other2">
						<el-col :span="15">
							<el-input v-model="ruleForm.other2"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								填写销售车辆品牌信息<br />
								示例：<span class="red">解放赋界（青岛）</span>
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="销售时间:" prop="buyDate">
						<el-col :span="15">
							<el-date-picker v-model="ruleForm.buyDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
								placeholder="选择日期">
							</el-date-picker>
						</el-col>
						<el-col :span="9">
							<div class="tips" style="line-height: 40px;">
								选择销售时间
							</div>
						</el-col>
					</el-form-item>
					<!-- 					<el-form-item label="字段3:" prop="other3">
							<el-input v-model="ruleForm.other3"></el-input>
						</el-form-item>
						<el-form-item label="字段4:" prop="other4">
							<el-input v-model="ruleForm.other4"></el-input>
						</el-form-item> -->
				</div>
				<div class="optional">
					<el-form-item label="客户名称:" prop="customerName">
						<el-col :span="15">
							<el-input v-model="ruleForm.customerName"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="单位:" prop="customerName">
						<el-col :span="15">
							<el-input v-model="ruleForm.customerCompany"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="联系电话:" prop="customerPhone">
						<el-col :span="15">
							<el-input v-model="ruleForm.customerPhone"></el-input>
						</el-col>
						<el-col :span="9">
							<div class="tips">
								非必填信息<br />
								建议规范填写，有助于后期销售数据管理
							</div>
						</el-col>
					</el-form-item>
					<el-form-item label="联系地址:" prop="customerAddress">
						<el-col :span="15">
							<el-input v-model="ruleForm.customerAddress"></el-input>
						</el-col>
					</el-form-item>
					<el-form-item label="备注:" prop="other3">
						<el-col :span="15">
							<el-input v-model="ruleForm.other3"></el-input>
						</el-col>
					</el-form-item>
				</div>
				<el-form-item>
					<el-button class="submit-btn" type="primary" @click="submitForm('ruleForm')"><i class="el-icon-document-checked"></i>提交审核</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		saveProductSale
	} from "../../api/productSale";

	export default {
		components: {},
		data() {
			return {
				ruleForm: {
					id: 0,
					vin: '',
					customerName: '',
					productName: '',
					buyDate: '',
					other1: '',
					other2: '',
					other3: '',
					other4: ''
				},
				rules: {
					vin: [{
						required: true,
						message: '请输入车辆vin',
						trigger: 'blur'
					}],
					productName: [{
						required: true,
						message: '请输入零件号码',
						trigger: 'blur'
					}],
					productCode: [{
						required: true,
						message: '请输入零件名称',
						trigger: 'blur'
					}],
					other1: [{
						required: true,
						message: '请输入销售车型码',
						trigger: 'blur'
					}],
					other2: [{
						required: true,
						message: '请输入品牌信息',
						trigger: 'blur'
					}],
					buyDate: [{
						type: 'string',
						required: true,
						message: '请选择销售时间',
						trigger: 'change'
					}],
				},
			};
		},
		computed: {
			vinUpper: {
				get() {
					return this.ruleForm.vin.toUpperCase()
				},
				set(val) {
					this.ruleForm.vin = val.toUpperCase()
					this.$nextTick(() => {
						this.$refs.ruleForm.validateField('vin')
					})
				}
			}
		},
		created() {
			if (this.$route.params.data) {
				this.ruleForm = JSON.parse(this.$route.params.data)
				console.log(this.ruleForm)
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						saveProductSale(this.ruleForm).then(response => {
							this.ruleForm.id = response.id
							this.$message({
								message: '保存成功',
								type: 'success'
							});
							this.$router.push({
								path: '/productSale'
							})
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
            generateCode() {
                this.ruleForm.code = Math.random().toString(10).substr(2, 4)
                this.$forceUpdate()
            }
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
